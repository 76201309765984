.backgrounds{
	position: absolute;
	top: 50vh;
	height: 125vh;
	width: calc(100% + 30px);
	left: -30px;
	z-index: 2;
	display: none;
}

.flowers{
	position: absolute;
}

.flower__left--1{
	top: 1500px;
}

.flower__right--1{
	top: 350px;
	right: 0;
}

.flower__left--2{
	top: 3800px;
}

.flower__right--2{
	top: 3600px;
	right: 0;
}

@media #{$medium-up}{
	.backgrounds{
		display: none;
		opacity: 0.4;
	}
}

@media #{$large-up}{
	.backgrounds{
		opacity: 1;
	}

	.flower__left--1{
		top: 1200px;
	}
}

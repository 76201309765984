.story-d {
	@include clearfix;
	background-size: cover;
	background-position: right 50%;
	background-repeat: no-repeat;
	padding: 60px 0;
	min-height: 500px;
	display: none;
	justify-content: center;
	flex-direction: column;

	.chapter {
		max-width: 460px;
		margin: 0 auto;
	}
}

.story-d__content {
	@include clearfix;
}

.story-d__content__block {
	@include span(12);
}

@include medium-up {
	.story-d {
		display: flex;

		.chapter {
			float: right;
		}
	}
}

@include large-up {
	.story-d {
		min-height: $partial-height-medium;
	}

	.story-d__content__block {
		@include span(8 last);
	}
}

@include xlarge-up {
	.story-d {
		.chapter {
			max-width: $frame-width-xsmall;
			margin: 0 auto;
			float: none;
		}
	}

	.story-d {
		min-height: $partial-height-large;
	}
}

@include smalllaptop {
	.story-d {
		min-height: 550px;
	}
}

$paddingTopMediumUp:60px;
$paddingTopLargeUp:100px;
$IMAGE_WIDTH: 1238;
$IMAGE_HEIGHT: 1050;
$ratio: ($IMAGE_HEIGHT/$IMAGE_WIDTH) * 100;
.experience-teaser {
  padding: 60px 0;
  position: relative;
  @include clearfix;
}

.experience-teaser__header {
  @include clearfix;
  text-align: center;
}

.experience-teaser__header__cell {
  @include span(12);
  position: relative;
  z-index: 10;
}

.experience-teaser__body__cell {
  @include span(12);
}

.experience-teaser__content {
  padding-bottom: 30px;
}

.experience-teaser__image-cell {
  width: 80%;
  margin: 30px auto;
}

.experience-teaser__image-block {
  position: relative;
  padding-bottom: #{$ratio + '%'};
}

.experience-teaser__image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.experience-teaser__footer {
  text-align: center;
}

@include medium-up {
  .experience-teaser {
    padding: 100px 0;
  }
  .experience-teaser__header {
    position: absolute;
    top: $paddingTopMediumUp;
    left: 0;
    text-align: left;
    @include clearfix;
  }
  .experience-teaser__header__cell {
    @include span(10);
  }
  .experience-teaser__content {
    max-width: $frame-width-small;
    margin: 0 auto;
    text-align: center;
  }
}

@include large-up {
  .experience-teaser__header {
    top: $paddingTopLargeUp;
  }
  .experience-teaser {
    padding-top: $paddingTopLargeUp;
  }
  .experience-teaser__image-cell {
    margin: 0;
    @include span(7 no-gutter);
    padding-top: 20%;
    padding-left: 5%;
  }
  .experience-teaser__body__cell {
    padding-top: 10%;
    @include span(5 last)
  }
  .experience-teaser__content {
    text-align: left;
  }
  .experience-teaser__footer {
    text-align: left;
  }
}

@include xlarge-up {
  .experience-teaser__image-cell {
    margin: 0 auto;
    width: 70%;
    padding-top: 8%;
  }
  .experience-teaser__body {
    position: absolute;
    right: 0;
    bottom: 100px;
    width: 100%;
  }
  .experience-teaser__body__cell {
    @include span(4 last)
  }
  .experience-teaser__header__cell {
    width: auto;
  }
  .experience-teaser__header {
    padding-left: 5%;
  }
  .experience-teaser {
    .e-head-serif-large {
      font-size: 80px;
    }
  }
}
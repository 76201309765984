.timelineslider {
	@include clearfix;
	padding: 60px 0;
	position: relative;

	.swiper__paging {
		margin-bottom: 30px;
	}

	.swiper__paging-wrapper {
		margin: 0 10%;
	}

	p {
		margin: 0;
	}

	.swiper__navbuttons {
		display: none;
	}
}

.ts__event {
	@include clearfix;
}

.ts__event__header {
	@include span(12);
	font-size: 30px;
	margin-bottom: 30px;
}

.ts__event__body {
	@include span(12);
}

.ts__eventlist-wrapper li {
	list-style: none;
}

.ts__event__content {
	padding-bottom: 40px;
}

.ts__event__lead {
	font-size: 30px;
	color: $color-gold;
	font-style: italic;
	font-family: $font-secondary;
	margin-bottom: 30px;
}

.ts__eventlist {
	.ts__event {
		transition: transform 0.5s;
	}

	.swiper-slide-next {
		.ts__event {
			transform: translateX(500px);
		}
	}

	.swiper-slide-prev {
		.ts__event {
			transform: translateX(-500px);
		}
	}

	.swiper-slide-active {
		.ts__event {
			transform: translateX(0);
		}
	}
}

.ts__event {
	@include clearfix;
}

.ts__event__image {
	margin-bottom: 30px;

	img {
		width: 100%;
	}
}

@include md-lg-up {
	.timelineslider {
		min-height: 900px;

		.swiper__navbuttons {
			display: block;
		}
	}

	.ts__event__image {
		@include span(5 last no-gutter);
		margin-left: gutter(1);
	}

	.ts__event__header {
		@include span(12);
	}

	.ts__event__body {
		@include span(12);
		float: none;
	}
}

@include large-up {
	.timelineslider {
		min-height: 900px;

		.swiper__paging-wrapper {
			margin: 0 20%;
		}
	}

	.ts__event__header {
		@include span(12);
	}

	.ts__event__image {
		@include span(4 last no-gutter)
		margin-left: 0;
	}

	.ts__event__content {
		@include span(8 no-gutter)
	}

	@include with-layout($susyLayoutInner) {
		.ts__event__header {
			h2 {
				@include span(8);
			}
		}

		.ts__event__cell-1 {
			@include span(6);
		}

		.ts__event__cell-2 {
			@include span(6 last)
		}
	}
}

@mixin mix-button-base() {
	outline: none;
	border: none;
	cursor: pointer;
	min-height: 60px;
	line-height: 23px;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 0;
	background: $primary_gold;
	color: $color-white;
	padding: 20px 40px 18px;
	letter-spacing: 5px;
}

.button {
	@include mix-button-base();
}

@media #{$medium-down} {
	.button {
		padding-left: 32px;
		padding-right: 32px;
		letter-spacing: 4px;
	}
}

.button-icon {
	outline: none;
	border: none;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	color: $primary_gold;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.2em;

	&.button--icon::after {
		position: relative;
		top: 4px;
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 20px;
		padding: 10px;
		margin: 0 0 0 15px;
		border: 1px solid $primary_gold;
		border-radius: 50%;
		transition: transform 0.5s ease-in-out;
		will-change: transform;
	}

	&.button-icon--plus:after {
		content: url("../assets/gfx/plus.svg");
		transform: scale(1);
	}

	&.button-icon--arrow:after {
		content: url("../assets/gfx/arrow-right-small.svg");
		padding: 10px 16px;
		transform: translateX(0);
	}

	&:hover {
		&.button-icon--plus::after {
			transform: scale(1.2);
		}

		&.button-icon--arrow::after {
			transform: translateX(10px);
		}
	}
}

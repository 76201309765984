.contact {
	@include clearfix;
	padding: 60px 0;
	position: relative;
}

.contact__content {
	padding: 30px 0;
}

.contact__frame {
	margin: 0 auto;
	text-align: center;
}

.contact__header {
	@include span(12);
	padding-left: 10%;
	padding-right: 10%;
}

.contact__body {
	@include span(12);
	padding-left: 10%;
	padding-right: 10%;
}

.contact__footer {
	position: relative;
	z-index: 21;
}

.contact__decor {
	position: absolute;
	top: 0;
	right: - ($app-offset-desktop + 1px);
	bottom: 0;
	width: 20%;
	z-index: 20;
	background-image: url("../assets/img/flower-decor-01.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left 20%;
	transform: scaleX(-1);
}

@include medium-up {
	.contact {
		padding-top: 6%;
		padding-bottom: 8%;
	}

	.contact__frame {
		max-width: $frame-width-xsmall;
	}
}

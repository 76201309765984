.craftintro {
	position: relative;
	padding-bottom: 10%;
}

.craftintro__image {
	position: absolute;
	left: 0;
	width: 100%;
	padding-bottom: 110%;
	background-size: 110%;
	background-repeat: no-repeat;
	z-index: 0;
	opacity: 0;
	transition: opacity 1s ease;

	&.b-loaded {
		opacity: 1;
	}
}

.craftintro__content {
	@include clearfix;
	position: relative;
	z-index: 1;
}

.craftintro__header__cell {
	@include span(12);
}

.craftintro__body__cell {
	@include span(12);
}

.craftintro__header {
	@include clearfix;
	padding-top: 80%;
	padding-bottom: 20px;
}

.craftintro__body {
	padding-bottom: 30px;
}

@include medium-up {
	.craftintro__header__cell {
		@include span(12);
	}

	.craftintro__body__cell {
		@include span(12);
	}

	.craftintro__content {
		text-align: left;
	}

	.craftintro__content__block {
		max-width: none;
		float: none;
	}
}

@include md-lg-up {
	.craftintro__header {
		padding-top: 14%;
	}

	.craftintro__header__cell {
		@include span(7 last);
	}

	.craftintro__body__cell {
		@include span(7 last);
	}

	.craftintro__image {
		width: 65%;
	}
}

@include large-up {
	.craftintro {
		padding-bottom: 16%;
		min-height: 860px;
	}

	.craftintro__image {
		width: 70%;
		padding-bottom: 80%;
	}

	.craftintro__header__cell {
		@include span(6);
		@include push(6);
	}

	.craftintro__body__cell {
		@include span(5);
		@include push(6);
	}
}

@include xlarge-up {
	.craftintro {
		padding-bottom: 22%;
	}

	.craftintro__image {
		width: 80%;
	}

	.craftintro__content__block {
		max-width: 1600px;
		margin: 0 auto;
	}

	.craftintro__header__cell {
		@include span(5);
		@include push(7);
	}

	.craftintro__body__cell {
		@include span(5);
		@include push(7);
	}
}

@include xxlarge-up {
	.craftintro {
		padding-bottom: 20%;
	}

	.craftintro__image {
		max-width: 1200px;
	}

	.craftintro__header {
		.e-head-large {
			font-size: 90px;
		}
	}

	.craftintro__header__cell {
		@include span(6);
		@include push(6);
	}

	.craftintro__body__cell {
		@include span(5);
		@include push(6);
	}
}

.history {
  @include clearfix;
  padding-bottom: 60px;
  z-index: 1;
  position: relative;
}

.history__header {
  @include span(12);
  text-align: center;
  padding-bottom: 40px;
}

.history__content {
  @include span(12);
  text-align: center;
}

.history__lead {
  max-width: $frame-width-xxsmall;
  margin: 0 auto 30px;
}

.history__media {
  @include span(12 no-gutter);
}

.history__media__block {
  padding-bottom: 51%;
  position: relative;
}

.history__media__swiper {
  padding-bottom: 60px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  img {
    width: 100%;
  }
}

.plainswiper-pagination {
  position: relative;
  margin: 20px -10px;
  text-align: center;
  .swiper-pagination-bullet {
    margin: 0 10px;
    height: 10px;
    width: 10px;
    background: $primary_gold;
    opacity: 0.5;
    transition: opacity 0.4s;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

@include medium-up {
  .history__content {
    text-align: center;
  }
}

@include large-up {
  .history {
    padding-bottom: 6%;
  }
  .history__header {
    @include span(12);
    text-align: left;
  }
  .history__content {
    @include span(6);
    text-align: left;
  }
  .history__lead {
    text-align: left;
    max-width: none;
    margin-bottom: 60px;
  }
  .history__media {
    @include span(6 no-gutters last);
    padding-top: 5px;
  }
  .history__media {
    .plainswiper-pagination {
      text-align: left;
    }
  }
  .history__container {
    max-width: calc(100vw - (100vw - 1440px)/2);
    float: right;
    width: 100%;
  }
}

@include xlarge-up {
  .history__content {
    @include span(4);
  }
  .history__media {
    @include span(8 no-gutters last);
  }
}
.plainswiper {
	@include clearfix;
	position: relative;

	.swiper__paging {
		margin: 0 auto 30px;
		min-width: 200px;
	}

	.swiper__paging__frame {
		margin: 0 auto;
	}
}

.plainswiper__slide__image {
	padding-bottom: 100%;
	position: relative;

	img {
		width: 100%;
	}
}

.plainswiper__slide__image-cell {
	width: 60%;
	margin: 0 auto 30px;
}

.plainswiper__slide__image__wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.plainswiper__slide__content {
	@include clearfix;
	text-align: center;
	max-width: $frame-width-xsmall;
	margin: 0 auto;
}

.plainswiper__slide__content-cell {
	@include span(12);
}

@include large-up {
	.plainswiper {
		padding-top: 30px;
		max-width: $frame-width;
		margin: 0 auto;

		.swiper__paging {
			position: absolute;
			top: 20px;
			right: 0;
			left: 0;
			z-index: 10;

			.swiper__paging__cell {
				float: right;
				width: span(5);
			}
		}

		.swiper__paging__frame {
			margin: 0;
			padding-left: 30px;
		}
	}

	.plainswiper__slide__image-cell {
		width: span(6);
		float: left;
		padding-left: span(1);
		margin-left: span(1);
	}

	.plainswiper__slide__content-cell {
		float: left;
		padding-right: gutter();
		width: span(5);
	}

	.plainswiper__slide__content {
		@include clearfix;
		text-align: left;
		padding-top: 80px;
		margin: 0;
	}

	.plainswiper__slide__body {
		max-width: 360px;
		padding-top: 15px;
	}
}

@include xlarge-up {
	.plainswiper {
		padding-top: 0;
	}

	.plainswiper__slide__content {
		padding-top: 120px;
	}
}

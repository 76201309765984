.block--black{
	background:$primary_black;
	margin: 55px 0 0 0;
	height: 1100px;
	width: calc(100% - 40px);
	overflow: hidden;
}

.text-center{
	text-align: center;
}

.text-right{
	text-align: right;
}

@media #{$medium-up} {
	.block--black{
		height: 1000px;
		margin: 165px 0 0 0;
	}
}

@media #{$large-up} {
	.block--black{
		height: 640px;
		margin: 220px 0 0 0;
	}
}

@media #{$xxlarge-up} {

  .block--black{
    margin: 237px 0 0 0;
		height: 700px;
  }

}

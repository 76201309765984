// Create a global Susy layout using our config.
$susy: (
  columns: 12,
  column-width: 240px,
  gutters: 1/4,
  gutter-position: inside-static,
  container: 2560px,
  global-box-sizing: border-box
);

$susyLayoutMedium: (
  gutters: 1/2,
);

$susyLayoutGrid: (
  //columns: 24,
  columns: 30,
  gutters: 1/10,
);

$susyLayoutInner: (
  gutters: 1/10,
);

$frame-width: 1540px;
$frame-width-medium: 1024px;
$frame-width-small: 764px;
$frame-width-xsmall: 660px;
$frame-width-xxsmall: 524px;

$small-range: (0rem, 640px);
$medium-range: (641px, 1024px);
$md-lg-up: (768px, 1024px);
$large-range: (1025px, 1400px);
$xlarge-range: (1400px, 1700px);
$xxlarge-range: (1701px);

$screen: "only screen";

$small-only: "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})";
$small-up: $screen;

$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$md-lg-up: "#{$screen} and (min-width:#{lower-bound($md-lg-up)})";

$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";

$xlarge-down: "#{$screen} and (max-width:#{upper-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";
$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";

$xxlarge-down: "#{$screen} and (max-width:#{upper-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";

$app-offset-desktop: 30px;

@mixin medium-up() {
  @include with-layout($susyLayoutMedium) {
    @media #{$medium-up}{
      @content
    }
  }
}

@mixin md-lg-up() {
  @include with-layout($susyLayoutMedium) {
    @media #{$md-lg-up}{
      @content
    }
  }
}

@mixin large-up() {
  @include with-layout($susyLayoutMedium) {
    @media #{$large-up}{
      @content
    }
  }
}

@mixin xlarge-up() {
  @include with-layout($susyLayoutMedium) {
    @media #{$xlarge-up}{
      @content
    }
  }
}

@mixin xxlarge-up() {
  @include with-layout($susyLayoutMedium) {
    @media #{$xxlarge-up}{
      @content
    }
  }
}


// GRID
@mixin grid-medium-up() {
  @include with-layout($susyLayoutGrid) {
    @media #{$medium-up}{
      @content
    }
  }
}

@mixin grid-md-lg-up() {
  @include with-layout($susyLayoutGrid) {
    @media #{$md-lg-up}{
      @content
    }
  }
}

@mixin grid-large-up() {
  @include with-layout($susyLayoutGrid) {
    @media #{$large-up}{
      @content
    }
  }
}

@mixin grid-xlarge-up() {
  @include with-layout($susyLayoutGrid) {
    @media #{$xlarge-up}{
      @content
    }
  }
}

@mixin grid-xxlarge-up() {
  @include with-layout($susyLayoutGrid) {
    @media #{$xxlarge-up}{
      @content
    }
  }
}


@mixin smalllaptop(){
  @media (max-height: 800px) and (min-width:#{lower-bound($large-range)}){
    @content
  }
}


body {
	font-family: $font-primary;
}

a,
b,
h1,
h2,
h3,
h4,
h5,
p {
	color: $primary_gold;
}

h1,
h2,
h3,
h4,
h5 {
	letter-spacing: 0.2em;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
}

p {
	font-size: 14px;
	line-height: 28px;
}

@media #{$medium-up} {
	p {
		font-size: 16px;
		line-height: 32px;
	}
}

.image {
	width: 100%;
	display: block;
}

// Kicker
.e-kicker-serif-upper {
	font-size: 20px;
	font-family: $font-secondary;
	text-transform: uppercase;
	margin: 0 0 0.6em;
	letter-spacing: 0.2em;

	@include large-up {
		font-size: 22px;
	}
}

// Head
%head-primary {
	font-family: $font-primary;
	margin: 0;
	line-height: 1;
	letter-spacing: 0.1em;
}

.e-head-small {
	@extend %head-primary;
	font-size: 26px;
	line-height: 1.1;
	letter-spacing: 0.2em;

	@include large-up {
		font-size: 32px;
	}
}

.e-head-medium {
	@extend %head-primary;
	font-size: 24px;
	line-height: 1.1;
	letter-spacing: 0.2em;

	@include medium-up {
		font-size: 36px;
	}

	@include large-up {
		font-size: 48px;
	}
}

.e-head-large {
	@extend %head-primary;
	font-size: 32px;
	line-height: 1.1;
	letter-spacing: 0.2em;

	@include medium-up {
		font-size: 36px;
	}

	@include large-up {
		font-size: 52px;
	}

	@include xlarge-up {
		font-size: 60px;
		letter-spacing: 0.3em;
	}

	@include xxlarge-up {
		font-size: 72px;
		letter-spacing: 0.3em;
	}
}

.e-head-xlarge {
	@extend %head-primary;
	font-size: 48px;

	@include medium-up {
		font-size: 64px;
		letter-spacing: 0.16em;
	}

	@include large-up {
		font-size: 78px;
		letter-spacing: 0.2em;
	}

	@include xlarge-up {
		font-size: 106px;
		letter-spacing: 0.3em;
	}

	@include xxlarge-up {
		font-size: 124px;
		letter-spacing: 0.3em;
	}
}

.e-head-serif-large {
	font-family: $font-secondary;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 27px;

	@include medium-up {
		font-size: 42px;
	}

	@include large-up {
		font-size: 54px;
	}

	@include xlarge-up {
		font-size: 60px;
	}

	@include smalllaptop() {
		font-size: 42px;
	}
}

.e-lead-serif-italic-fixed {
	font-size: 30px;
	color: $color-gold;
	font-style: italic;
	line-height: 1.2;
	font-family: $font-secondary;
	margin-bottom: 30px;
}

// Lead
.e-lead-serif-italic {
	&,
	p {
		font-family: $font-secondary;
		font-style: italic;
		font-size: 18px;
		line-height: 1.6;
		margin: 0 0 0.6em;
	}

	@include medium-up {
		&,
		p {
			font-size: 22px;
		}
	}
}

.e-lead-serif-italic-large {
	@extend .e-lead-serif-italic;

	&,
	p {
		font-size: 18px;
		line-height: 1.4;
	}

	@include medium-up {
		&,
		p {
			font-size: 22px;
		}
	}

	@include large-up {
		&,
		p {
			font-size: 30px;
		}
	}

	@include xlarge-up {
		&,
		p {
			font-size: 36px;
		}
	}
}

@include medium-up {
	.e-kicker-serif-upper + .e-head-xlarge {
		margin-top: 0.4em;
	}
}

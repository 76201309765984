.u-text-center{
    text-align: center !important;
}

.u-text-left{
    text-align: left !important;
}

.u-text-right{
    text-align: right !important;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}
.global-footer {
  background-color: $color-darkgrey;
  padding: 20px 10% 0; //@include clearfix;
}

.global-footer__nextbutton {
  display: block;
  margin: 116px auto 0;
  max-width: 600px;
  background: $color-ivory-light;
  text-decoration: none;
  text-align: center;
  padding-top: 30px;
}

.global-footer__logo {
  margin: 20px auto;
  width: 240px;
  img {
    width: 100%;
  }
}

.global-footer__nextbutton__text {
  color: $color-darkgrey;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 16px;
}

.global-footer__nextbutton__icon {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.experience-page {
	background-color: $primary_black;
	.collection-wrap {
		position: relative;
		background: transparent;
		border-top: none; // fix for firefox
		overflow: hidden;
		height: 90%;
	}
}

#back-button {
	position: absolute;
	top: 2rem;
	left: 2rem;
	cursor: pointer;
	@media #{$large-up} {
		top: 2.5rem;
		left: 2.2rem;
		transform: scale(1.5);
	}
	@media #{$medium-down} {
		top: 0.5rem;
	}
}

#app {
	&.xtra-padding {
		@media #{$medium-down} {
			min-height: calc(100% - 80px);
			margin: 50px 30px 30px;
			border: 1px solid #b4965a;
		}
	}
}

#model {
	&>canvas {
		position: absolute;
		top: calc(50% + 40px);
		left: 20px;
		right: 20px;
		margin: 0 auto;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
		transform: translate(0%, -50%);
		@media screen and (min-width: 641px) {
			left: 40px;
			width: calc(100% - 80px);
			height: calc(100% - 80px);
		}
		@media screen and (min-width: 831px) {
			top: calc(50% - 40px);
		}
		@media screen and (min-width: 1300px) {
			right: auto;
			margin: 0;
		}
	}
}

#progress {
	position: absolute;
	z-index: 10000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	color: $primary_gold;
	font-family: $font-primary;
	font-size: 4rem;
}

#mappings {
	position: absolute;
	z-index: 10000;
	left: 0;
	top: 20px;
	right: 0;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	@media screen and (min-width: 831px) {
		top: auto;
		bottom: 20px;
	}
	@media screen and (min-width:831px) and (min-height:1120px) {
		bottom: 40px;
	}
	.map {
		position: relative;
		flex-grow: 0;
		flex-basis: 80px;
		padding-bottom: 80px;
		margin: 0 5px;
		opacity: 0.5;
		border: 1px solid $primary_gold;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		&:hover {
			opacity: 0.75;
		}
		&.active {
			opacity: 1;
		}
	}
}

.showcase__textbox {
	position: absolute;
	z-index: 10001;
	top: 0;
	right: 50px;
	bottom: 0;
	width: 24vw;
	display: flex;
	justify-content: center;
	flex-direction: column;
	transition: transform 0.75s ease, background 0.75s ease;
	transform: translate(0%, 0%);
	will-change: transform background;
	@media screen and (max-width: 1300px) {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		background: $primary_black;
		.showcase__textbox {
			width: 100%;
		}
		&.closed {
			transform: translate(100%, 0%);
			background: transparent;
		}
	}
	@media screen and (max-width: 830px) {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		.showcase__textbox {
			width: 100%;
		}
		&.closed {
			transform: translate(0%, 100%);
			background: transparent;
		}
	}
}

.showcase__bodytext {
	position: relative;
	margin: 0 auto;
	max-width: 600px;
	padding: 80px 40px;
	overflow-x: hidden;
	overflow-y: auto;
	@media screen and (min-width: 1301px) {
		padding: 20px 20px 20px 0;
		pointer-events: all;
	}
}

.showcase__arrow {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -50px;
	display: none;
	width: 20px;
	height: 100%;
	margin: auto;
	pointer-events: all;
	z-index: 1001;
	svg {
		position: absolute;
		top: 50%;
		cursor: pointer;
		transition: transform 0.75s ease;
		transform: translateY(-50%) translateX(100px) rotate(180deg);
		will-change: transform;
		.closed & {
			transform: translateY(-50%) translateX(-20px) rotate(0deg);
		}
	}
	@media screen and (max-width: 1300px) {
		display: block;
	}
	@media screen and (max-width: 830px) {
		top: -90px;
		right: 0;
		bottom: auto;
		left: 80px;
		width: 40px;
		height: 60px;
		transform: translateX(-100%);
		svg {
			top: 0;
			right: 0;
			width: auto;
			height: 70px;
			margin: auto;
			transform: translateY(80px) rotate(270deg);
			.closed & {
				transform: translateY(0) rotate(90deg);
			}
		}
	}
}

.button {
	margin: 20px 0;
}

#mappings .map:nth-of-type(1) {
	background-image: url('../../dist/assets/img/maps/motif1.jpg');
}

#mappings .map:nth-of-type(2) {
	background-image: url('../../dist/assets/img/maps/motif2.jpg');
}

#mappings .map:nth-of-type(3) {
	background-image: url('../../dist/assets/img/maps/motif3.jpg');
}

#mappings .map:nth-of-type(4) {
	background-image: url('../../dist/assets/img/maps/motif4.jpg');
}
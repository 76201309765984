.content {
	position: relative;
}

.content__heading {
	font-size: 32px;
	line-height: 40px;
}

.content__dash::before {
	display: block;
	float: left;
	width: 25px;
	height: 35px;
	margin-bottom: 10px;
	content: url("../assets/gfx/dash.svg");
}

.content__dash--white::before {
	content: url("../assets/gfx/dash-white.svg");
}

.content__dash--center::before {
	float: none;
	margin: 0 auto 20px;
}

.content__text {
	max-width: 800px;
}

.content__text--centered {
	float: none;
	margin: 0 auto;
}

@include medium-up{
	.content__heading {
		font-size: 50px;
		line-height: 60px;
	}

	.content__text {
		max-width: 480px;
	}

	.content__dash::before {
		width: 35px;
		height: 45px;
		margin-bottom: 20px;
	}
}

@include large-up{
	.content__heading {
		font-size: 55px;
		line-height: 75px;
	}
}

@include xxlarge-up{
	.content__heading {
		font-size: 75px;
		line-height: 95px;
	}
}

.ncollection {
  @include clearfix;
  &.collection {
    padding: 0;
  }
  .collection-modal-close {
    z-index: 100;
    display: block;
  }
}

.ncollection__header {
  text-align: center;
  padding: 60px 0;
}

.ncollection-products {
  max-width: 1600px;
  margin: 0 auto;
}

.ncollection__section {
  padding: 0 gutter();
  @include clearfix;
}

.ncollection__cell {
  margin-bottom: gutter();
}

.ncollection__product {
  cursor: pointer;
}

.ncollection__block {
  padding-bottom: 100%;
  position: relative;
  &:focus {
    outline: none;
  }
}

.ncollection__plusicon {
  position: absolute;
  justify-content: center;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid $color-gold;
  border-radius: 50%;
  z-index: 100;
  margin-top: -20px;
  transition: 0.4s ease;
  opacity: 0;
  .button-icon {
    width: 20px;
    height: 20px;
    display: block;
  }
}

.ncollection__col-b {
  .ncollection__block {
    padding-bottom: 100%;
    position: relative;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 12px 6px rgba(#000, 0.3);
      opacity: 0;
      transition: 0.3s ease;
    }
    &:hover,
    &:focus {
      &:after {
        opacity: 1;
      }
    }
    &:hover {
      .ncollection__plusicon {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}

.ncollection__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border: 1px solid $color-gold;
  z-index: 100;
}

.ncollection__category {
  @extend .ncollection__image;
  background-image: url('../assets/img/group-decor-bg-a.jpg');
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ncollection__category__title {
  text-align: center;
  font-family: $font-secondary;
  display: block;
  font-style: italic;
  text-transform: none;
  font-size: 40px;
  padding: 0 1em;
  font-weight: normal;
  @include grid-md-lg-up {
    font-size: 36px;
  }
  @include grid-medium-up {
    font-size: 22px;
  }
  @include grid-large-up {
    font-size: 28px;
  }
  @include grid-xlarge-up {
    font-size: 36px;
  }
}

@include grid-medium-up {
  .ncollection__col-a {
    @include span(8 no-gutter);
  }
  .ncollection__col-b {
    @include span(22 last no-gutter);
  }
  .ncollection__cell {
    @include span(30);
  }
}

@include grid-md-lg-up {
  .ncollection__col-a {
    @include span(6 no-gutter);
    .ncollection__cell {
      @include span(30);
    }
  }
  .ncollection__col-b {
    @include span(24 no-gutter);
    .ncollection__cell {
      @include span(6 of 24);
      margin-bottom: gutter()*2;
    }
    .ncollection__cell:nth-child(6) {
      clear: both;
    }
  }
  .ncollection__section--heros {
    .ncollection__col-b {
      .ncollection__cell:first-child {
        @include span(12 of 24);
      }
    }
  }
}
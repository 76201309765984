.story-a {
	@include clearfix;
	background: $color-lightgrey;
	padding-bottom: 60px;
	position: relative;
	display: none;
}

.story-a__image {
	height: 400px;
	background-size: 180%;
	background-position: 50% 90%;
	background-repeat: no-repeat;
}

.story-a__content {
	@include clearfix;
}

.story-a__content__cell {
	@include span(12);
}

@include medium-up {
	.story-a {
		display: block;
	}
}

@include large-up {
	.story-a {
		min-height: $partial-height-medium;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-bottom: 20px;
	}

	.story-a__image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 50%;
		height: auto;
		background-size: 200%;
		background-position: 100% 50%;
	}

	.story-a__content__cell {
		@include span (7 last);
		position: relative;
		z-index: 2;
	}
}

@include xlarge-up {
	.story-a {
		height: 880px;
	}
}

@include smalllaptop {
	.story-a {
		min-height: 560px;
	}
}

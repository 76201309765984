$offset: 187;
$duration: 1.4s;

.spinner {
	animation: rotator $duration linear infinite;
	width: 100%;
	height: 100%;

	.path {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
	}
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

@keyframes colors {
	0% {
		stroke: $color-gold;
	}

	25% {
		stroke: $color-gold;
	}

	50% {
		stroke: $color-gold;
	}

	75% {
		stroke: $color-gold;
	}

	100% {
		stroke: $color-gold;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}

	50% {
		stroke-dashoffset: $offset/4;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}

@include keyframes(loading) {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background: $light_gold;
	z-index: 10400;

	small {
		display: block;
		margin-top: 15px;
		width: 100%;
		text-align: center;
		color: $primary_gold;
		opacity: 1;
		text-indent: 10px;
		@include animation('loading 2s infinite');
	}
}

.loader__logo {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	margin: 0 auto;
	transform: translateY(-50%);
	width: 100px;
	height: 180px;
	overflow: hidden;
	opacity: 0;
}

.logo-item__top {
	margin-bottom: 20px;
}

.logo-item__bot {
	width: 200px;
}

.contactoverlay {
	padding: 0;
	width: 100%;
	transform: none;

	.contactoverlay__block {
		position: relative;
		z-index: 1;
		padding: 100px 0;
		background: $color-ivory;
		height: 100%;
		overflow: auto;
		@include clearfix;
		transform: translateX(100%);
		transition: transform 0.3s ease, opacity 0.1s ease;
	}

	&.modal__wrap.display--modal {
		transform: none;

		.contactoverlay__bg {
			opacity: 1;
		}

		.contactoverlay__block {
			transform: translateX(0%);
			transition: transform 0.4s ease, opacity 0.4s ease;
		}
	}
}

.contactoverlay__bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba($color-darkgrey, 0.8);
	transition: 0.2s ease;
	opacity: 0;
}

.contactoverlay__frame {
	max-width: $frame-width-small;
	margin: 0 auto;
	padding: 0 gutter();
}

.contactoverlay__header {
	margin-bottom: 30px;
}

@include medium-up {
	.contactoverlay__frame {
		padding: 0 10%;
	}

	.contactoverlay {
		.fd_form {
			margin: 0 -12px;
		}

		.fd_form__field {
			width: 50%;
			padding: 0 12px;
			float: left;
		}
	}
}

@include md-lg-up {
	.contactoverlay__block {
		width: 80vw;
		float: right;
	}
}

@include large-up {
	.contactoverlay__block {
		width: 60vw;
	}
}

@include xlarge-up {
	.contactoverlay__block {
		width: 40vw;
	}
}

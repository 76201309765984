.collection {
    padding: 50px 1rem;
    @extend #app;
}

.collection-wrap {
    padding: 0.1px;
    background-color: #2e2e2e;
    background-image: url('../assets/img/bg-tile-dark.jpg');
}

.collection-title {
    margin-left: 1rem;
}

.collection-products {
    display: flex;
    flex-flow: row wrap;
    padding-top: 1rem;
}

.collection-category {
    position: relative;
    width: calc(25% - 30px);
    height: 0;
    margin: 15px;
    padding: 0 0 calc(25% - 30px) 0;
    transition: transform 0.25s ease;
    outline-color: transparent;
    background-image: url('/dist/assets/img/sandstone-the-wave-rock-nature-50570.jpeg');
}

.collection-category-large {
    width: calc(50% - 30px);
    padding: 0 0 calc(50% - 30px) 0;
    background-image: url('/dist/assets/img/sandstone-the-wave-rock-nature-50570.jpeg');
    margin: 15px;
}

.collection-category-header {
    text-align: center;
    color: #EEE;
    font-size: 4em;
}

.collection-item {
    position: relative;
    width: calc(25% - 30px);
    height: 0;
    margin: 15px;
    padding: 0 0 calc(25% - 30px) 0;
    cursor: pointer;
    transition: transform 0.25s ease;
    outline-color: transparent;
    @media #{$medium-down} {
        width: calc(50% - 30px);
        padding: 0 0 calc(50% - 30px) 0;
    }
    .button {
        position: absolute;
        top: 50%;
        left: 50%; // Todo add another class to check for to avoid problems
        display: none;
        transform: translate(-50%, -50%);
    }
    &:focus,
    &:hover {
        transform: scale(1.05);
        .collection-image--container {
            border: 1px solid #b4965a;
        }
        .button {
            display: inline;
            &:after {
                margin: 0;
            }
        }
    }
}

.collection-image--container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-position: center;
    background-size: cover;
}

.collection-disable-background {
    overflow: hidden;
    pointer-events: none;
}

.product-image {
    max-width: 100%;
}

#collection-modal {
    position: fixed;
    z-index: 10200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    cursor: pointer;
    transition: transform 0.25s ease;
    transform: scale(0);
    pointer-events: all;
    background: $primary_black;
    &.modal-open {
        transform: scale(1);
    }
    &-image {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        display: block;
        max-width: 90vw;
        max-height: 90vh;
        margin: 0 auto;
        transform: translateY(-50%);
    }
    &-motive {
        transform: translateY(-1.5em);
    }
    .collection-modal {
        &-text {
            position: absolute;
            top: calc(100% + 60px);
            right: 0;
            left: 0;
            transition: top 0.25s ease;
            text-align: center;
            color: #b4965a;
            @media #{$medium-down} {
                font-size: 1em;
            }
        }
        &-close {
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}
.menu-icon {
	margin: auto;
	width: 36px;
	height: 38px;
	cursor: pointer;
	overflow: hidden;

	&:after,
	&:before,
	.menu-icon-line {
		background: $color-gold;
		content: '';
		display: block;
		height: 2px;
		margin: 8px 0;
		width: 100%;
		transition: all 0.25s ease;
	}
}

.menu-open {
	.menu-icon {
		&:after {
			transform: translateY(-10px) rotate(-135deg);
		}

		.menu-icon-line {
			transform: rotate(180deg);
			opacity: 0;
		}

		&:before {
			transform: translateY(10px) rotate(135deg);
		}
	}
}

$inputHeight: 40px;

.fd_form__fieldset {
	@include clearfix;
}

.fd_form__field {
	margin-bottom: 26px;
}

.fd_form__label {
	padding-bottom: 10px;
}

.fd_form__label__elm {
	color: $color-gold;
	font-family: $font-primary;
	font-weight: bold;
}

.fd_form__input {
	position: relative;
}

.fd_form__input__elm {
	width: 100%;
	height: $inputHeight;
	border: 1px solid $color-gold;
	outline: none;
	padding: 5px 10px;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

.fd_form__error {
	position: absolute;
	top: $inputHeight;
	height: 30px;
	left: 0;
	width: 100%;
	color: $color-red;
	padding: 3px 10px 3px 0;
	font-size: 14px;
}

.fd_form__radiogroup {
	margin: 5px 0;
}

.fd_form__radio {
	color: $color-gold;
	margin-bottom: 10px;
	cursor: pointer;

	label {
		cursor: pointer;
	}

	input[type="radio"] {
		-webkit-appearance: none;
		position: absolute;
		opacity: 0;

		+ .radio-label:before {
			content: '';
			background: $color-white;
			border-radius: 100%;
			border: 1px solid $color-gold;
			display: inline-block;
			width: 25px;
			height: 25px;
			position: relative;
			top: -0.2em;
			margin-right: 1em;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all 250ms ease;
		}

		&:checked + .radio-label:before {
			background-color: $color-gold;
			box-shadow: inset 0 0 0 5px $color-white;
		}

		&:focus + .radio-label:before {
			outline: none;
			border-color: $color-gold;
		}

		&:disabled + .radio-label {
			opacity: 0.4;

			&:before {
				box-shadow: inset 0 0 0 5px $color-white;
				border-color: $color-darkgrey;
				background: $color-darkgrey;
			}
		}

		+ .radio-label {
			&:empty {
				&:before {
					margin-right: 0;
				}
			}
		}
	}
}

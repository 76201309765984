.parallax__group{
	position: relative;
	left: 0;
	right: 0;
	width: 100%;
}

.parallax__layer{
	position: relative;
	left: 0;
	right: 0;
}

.parallax__mobile{
	position: absolute;
	top: 0;
}

.parallax__layer--fore{
	z-index: 6;
}

.parallax__layer--base{
	z-index: 5;
}

.parallax__layer--back{
	z-index: 4;
}

.parallax__layer--deep{
	z-index: 3;
}

.parallax__layer--below{
	z-index: 2;
}
 
.mouse-parallax{
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 200px;
}

@media #{$medium-up}{
	.parallax__group{
		left: 30px;
		right: 30px;
		width: calc(100% - 60px);
	}

	.parallax__layer,
	.mouse-parallax{
		position: absolute;
	}
}

$insirationImageRatio: 64.75%;
$smallHeight: 500px;
$largeHeight: 600px;
$xLargeHeight: 800px;

.inspiration__container {
  background: $color-darkgrey;
  @include clearfix;
  position: relative;
  z-index: 1;
  .swiper__paging {
    margin-bottom: 40px;
  }
  .swiper__navbuttons {
    display: none;
  }
}

.inspiration__content {
  @include clearfix;
  padding: 40px 0 60px;
  display: flex;
  flex-direction: column-reverse;
}

.inspiration__content__text {
  max-width: $frame-width-small;
  margin: 0 auto;
  @include clearfix;
  .e-head-small {
    color: $color-ivory-light;
    text-align: center;
  }
}

.inspiration__description {
  text-align: center;
  p {
    color: $color-ivory-light;
  }
}

.inspiration__pagingframe {
  width: 220px;
  margin: 0 auto;
}

.inspiration__slide {
  padding-bottom: $insirationImageRatio;
  position: relative;
}

.inspiration__slide__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.inspiration__content {
  @include span(12);
}

@include md-lg-up {
  .inspiration__container {
    .swiper__navbuttons {
      display: block;
    }
  }
}

@include medium-up {
  .inspiration__container {
    width: 100vw;
    margin-left: - ($app-offset-desktop + 1px);
  }
  .inspiration__frame {
    padding-left: $app-offset-desktop;
    padding-right: $app-offset-desktop;
  }
  .inspiration__content__wrapper {
    max-width: calc(100vw - (100vw - 1440px)/2);
    float: right;
    width: 100%;
  }
}

@include large-up {
  .inspiration {
    .swiper__paging {
      margin-bottom: 0;
    }
  }
  .inspiration__container {
    height: $largeHeight;
  }
  .inspiration__swiper-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: span(6);
    .swiper-slide {
      height: $largeHeight;
    }
  }
  .inspiration__slide {
    padding-bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .inspiration__content {
    height: $largeHeight;
    flex-direction: column;
    justify-content: center;
    @include span(6 last);
  }
  .inspiration__content__inner {
    max-width: 400px;
  }
  .inspiration__content__text {
    .e-head-small {
      text-align: left;
    }
  }
  .inspiration__description {
    text-align: left;
  }
  .inspiration__pagingframe {
    margin: 3% 0;
  }
}

@include xlarge-up {
  .inspiration__container {
    height: $xLargeHeight;
  }
  .inspiration__swiper-frame {
    .swiper-slide {
      height: $xLargeHeight;
    }
  }
  .inspiration__content {
    height: $xLargeHeight;
  }
  .inspiration__content {
    @include span(5 last);
  }
  .inspiration__swiper-frame {
    width: span(7);
  }
}

@include smalllaptop {
  .inspiration__container {
    height: $smallHeight;
  }
  .inspiration__content {
    height: $smallHeight;
  }
  .inspiration__swiper-frame {
    .swiper-slide {
      height: $smallHeight;
    }
  }
}
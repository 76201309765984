.behindscenes {
	@include clearfix;
	padding-bottom: 60px;
	z-index: 1;
	position: relative;
}

.behindscenes__header {
	@include span(12);
	text-align: center;
	padding-bottom: 40px;
}

.behindscenes__content {
	@include span(12);
	text-align: center;
}

.behindscenes__lead {
	max-width: $frame-width-xxsmall;
	margin: 0 auto 30px;

	p:first-child {
		margin-top: 0;
	}
}

.behindscenes__media {
	@include span(12 no-gutter);
}

.behindscenes__media__block {
	padding-bottom: 51%;
	position: relative;
}

.behindscenes__media__swiper {
	padding-bottom: 60px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	img {
		width: 100%;
	}
}

.plainswiper-pagination {
	position: relative;
	margin: 20px -10px;
	text-align: center;

	.swiper-pagination-bullet {
		margin: 0 10px;
		height: 10px;
		width: 10px;
		background: $primary_gold;
		opacity: 0.5;
		transition: opacity 0.4s;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
	}
}
@include medium-up {
	.behindscenes__content {
		text-align: center;
	}

	.behindscenes__media {
		margin-bottom: 40px;
	}

	.behindscenes__lead {
		margin-bottom: 40px;
	}
}
@include large-up {
	.behindscenes__header {
		@include span(8);
		text-align: left;
	}

	.behindscenes__content {
		@include span(6);
		text-align: left;
	}

	.behindscenes__lead {
		text-align: left;
		max-width: none;
		margin-bottom: 60px;
	}

	.behindscenes__media {
		@include span(6 no-gutters last);
		padding-top: 5px;
	}

	.behindscenes__media {
		.plainswiper-pagination {
			text-align: left;
		}
	}

	.behindscenes__container {
		max-width: calc(100vw - (100vw - 1440px)/2);
		float: right;
		width: 100%;
	}
}
@include xlarge-up {
	.behindscenes__header {
		@include span(6);
	}

	.behindscenes__content {
		@include span(4);
	}

	.behindscenes__media {
		@include span(8 no-gutters last);
	}
}

$primary_gold: #b4965a;
$primary_black: #2e2e2d;

$light_gold: #F7F6F2;
$modal_grey: #979796;

$color-white: #fff;
$color-ivory-light: #fbfaf6;
$color-ivory: #f2f3ec;
$color-lightgrey: #e0e0e0;
$color-darkgrey: #3c3a3d;
$color-gold: $primary_gold;
$color-red: #963a2e;

.modal__wrap {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 10100;
  transition: transform 0.5s;
  transform: translateX(100vw);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  padding: 0 30px;
  &.display--modal {
    transform: translateX(0vw);
  }
  &.unhide--modal {
    visibility: visible;
  }
}

.modal__close {
  position: fixed;
  top: 40px;
  right: 40px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  fill: $primary_gold;
  z-index: 10200;
  display: none;
  &.display--modal {
    display: block;
  }
}

@media #{$medium-up} {
  .modal__wrap {
    padding: 0 30px;
  }
}

@media #{$large-up} {
  .modal__wrap {
    width: calc(100vw - 31px);
  }
}
.modal__history {
	background: $primary_black;
	ol {
		@include list-resetter;
	}
}

.historyslider__indicator {
	position: relative;
	display: block;
	height: 15px;
	width: 0;
	cursor: pointer;
	&::after {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		content: '';
		height: 1px;
		width: 100%;
		background: $modal_grey;
		transition: height 0.25s;
	}
}

.historyslider__indicator--active::after {
	height: 5px;
}

.historyslider__item {
	width: 100%;
	float: left;
	padding-bottom: 100px;
}

.historyslider__item--wrap {
	@include clearfix;
	position: relative;
	margin: 120px 0;
	.chapterline--inview {
		width: calc(100% - 50px);
	}
}

.historyslider__item--content {
	@include span(12);
}

.historyslider__item--images {
	@include span(12);
	position: relative;
	margin-top: 30px;
	.parallax__layer--back {
		position: absolute;
		top: 170px;
		img {
			width: 45%;
		}
	}
	.parallax__layer--deep img {
		width: 100%;
	}
}

.historyslider__chapter {
	font-size: 20px;
	color: $modal_grey;
	letter-spacing: 0.2em;
	.lines {
		position: relative;
		top: 5px;
		fill: $modal_grey;
		height: 26px;
		width: 26px;
	}
}

.historyslider__heading {
	font-size: 28px;
	line-height: 40px;
	margin: 40px 0 0 0;
}

.indicators {
	position: fixed;
	top: 50%;
	left: 50px;
	transform: translateX(-50%);
	visibility: hidden;
	z-index: -600;
	display: none !important; // temp
	@include list-resetter;
	&.display--modal {
		display: block;
	}
}

.chapterline {
	position: absolute;
	top: 40px;
	right: 40px;
	width: calc(100% - 10px);
	display: block;
	height: 1px;
	background: $modal_grey;
	transition: width 1s ease-in-out;
}

.chapterline__dot {
	position: absolute;
	top: -9px;
	right: -18px;
	display: block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 1px solid $modal_grey;
	&::after {
		position: absolute;
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		content: '';
		background: $modal_grey;
		border-radius: 50%;
	}
}

@include medium-up {
	.historyslider__item--wrap .parallax__layer {
		position: relative;
	}
	.historyslider__item--content {
		padding: 0;
	}
	.historyslider__item--images {
		padding: 0;
		width: calc(100% - 60px);
	}
	.historyslider__item--wrap {
		margin: 180px 0;
	}
	.historyslider__heading {
		font-size: 35px;
		line-height: 40px;
		margin: 50px 0 0 0;
		padding-right: 30px;
	}
	.historyslider__paragraph p {
		padding-right: 60px;
	}
	.chapterline {
		top: 45px;
		right: calc(50% + 138px);
	}
}

@include large-up {
	.historyslider .parallax__layer--deep img {
		margin: auto;
	}
	.historyslider__item--content {
		@include span(6 no-gutters);
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
		.parallax__layer {
			float: right;
			max-width: 550px;
		}
	}
	.historyslider__item--images {
		@include span(6 last);
	}
	.historyslider__heading {
		font-size: 50px;
		line-height: 60px;
		padding-right: 0;
	}
	.historyslider__paragraph p {
		padding-right: 0;
	}
	.item--inview {
		.chapterline {
			width: 40%;
		}
		.historyslider__item--content {
			opacity: 1;
		}
		.parallax__layer--back,
		.parallax__layer--deep {
			transform: translate(0, 0);
		}
	}
	.indicators {
		visibility: visible;
		z-index: 90000;
	}
}

@include xlarge-up {
	.historyslider__item--content {
		padding: 0 60px;
		.parallax__layer {
			float: right;
		}
	}
	.historyslider__item--images {
		.parallax__layer--back {
			left: -90px;
			img {
				width: auto;
			}
		}
	}
}
.princess {
	@include clearfix;
	padding-top: 60px;
	position: relative;
}

.princess__content {
	z-index: 2;
	position: relative;
	padding-bottom: 60px;
}

.princess__header {
	max-width: $frame-width-xxsmall;
	margin: 0 auto;
	position: relative;
	z-index: 3;
}

.princess__header-frame {
	@include clearfix;
	max-width: $frame-width-small;
	margin: 0 auto;
}

.princess__header-cell {
	@include span(12);
	text-align: center;
}

.princess__image-container {
	position: relative;
	padding-bottom: 120%;
}

.princess__image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
}

.princess__image--top {
	z-index: 4;
}

.princess__container {
	position: relative;
}

.princess__content-cell {
	@include span(12);
}

.princess__content__button {
	margin-top: 30px;
	text-align: center;
}

.modal__princess {
	background: $primary_black;

	ol {
		@include list-resetter;
	}
}

@include medium-up {
	.princess__content {
		max-width: $frame-width-xsmall;
		margin: -8% auto 0;
		text-align: center;
		padding-bottom: 100px;
	}

	.princess__header {
		max-width: none;
	}
}

@include large-up {
	.princess {
		padding-top: 100px;
	}

	.princess__header-frame {
		max-width: $frame-width-xsmall;
	}

	.princess__header {
		max-width: none;
	}
}

@include xlarge-up {
	.princess__image-container {
		padding-bottom: 110%;
	}

	.princess__content-cell {
		position: absolute;
		top: 10%;
		right: 5%;
		float: none;
		width: span(3);
		padding: 0;
	}

	.princess__header-frame {
		max-width: $frame-width-medium;
	}

	.princess__content {
		margin: 0;
		width: 360px;
		text-align: left;
	}

	.princess__content__button {
		text-align: left;
	}
}

.image-caption {
	@include clearfix;
	padding-top: 60px;
	padding-bottom: 60px;
	z-index: 1;
	position: relative;
}

.image-caption__header {
	@include span(12);
	text-align: center;
	padding-bottom: 40px;
}

.image-caption__content {
	@include span(12);
	text-align: center;
}

.image-caption__media {
	@include span(12 no-gutter);

	img {
		width: 100%;
		height: auto;
	}
}

@include medium-up {
	.image-caption__media {
		margin-bottom: 40px;
	}
}

@include large-up {
	.image-caption {
		padding-top: 180px;
		padding-bottom: 120px;
	}

	.image-caption__header {
		@include span(8);
		text-align: left;
	}

	.image-caption__content {
		@include span(6);
		text-align: left;
	}

	.image-caption__media {
		@include span(6 no-gutters);
		padding-top: 5px;

		.invert & {
			@include span(6 no-gutters last);
		}
	}
}

@include xlarge-up {
	.image-caption__header {
		@include span(6);
	}

	.image-caption__content {
		@include span(4);
	}

	.image-caption__media {
		@include span(7 no-gutters);

		.invert & {
			@include span(8 no-gutters last);
		}
	}
}

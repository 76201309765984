// Generic list-resetter
// Apply to UL or OL to remove standard list-styling via @extend

@mixin list-resetter(){
	margin: 0;
	padding: 0;
	li {
		list-style: none outside;
		padding: 0;
		margin: 0;
	}
}



.ncollection-carousel__itemscontainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.ncollection-carousel__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.ncollection-carousel__spinner {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  opacity: 1;
  transition: 0.4s ease;
}

.ncollection-carousel__item__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-darkgrey;
}

.ncollection-carousel__item__image {
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s;
}

.ncollection-carousel__item__text {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
  padding: 0 gutter()*2 5vh;
}

.ncollection-carousel__item__title {
  font-size: 18px;
  font-family: $font-secondary;
  font-style: italic;
  color: $color-ivory-light;
  margin-bottom: 0.6em;

  @include medium-up {
    font-size: 22px;
  }

  @include large-up {
    font-size: 26px;
  }
}

.ncollection-carousel__item__articlenum {
  color: $color-ivory-light;
}

.ncollection-carousel__item--loaded {
  .ncollection-carousel__item__image {
    opacity: 1;
  }
  &:after {
    display: none;
  }
  .ncollection-carousel__spinner {
    opacity: 0;
  }
}

.ncollection-carousel__nav {
  z-index: 1;
}

.ncollection-carousel__nav__btn-prev,
.ncollection-carousel__nav__btn-next {
  position: absolute;
  height: 80px;
  width: 80px;
  display: flex;
  top: 50%;
  margin-top: -40px;
  flex-direction: column;
  justify-content: center;
  svg {
    fill: $color-gold;
  }
}

.ncollection-carousel__nav__btn-prev {
  left: 0;
}

.ncollection-carousel__nav__btn-next {
  right: 0;
}
.storyintro {
	@include clearfix;
	padding: 76px 0 100px;
}

.storyintro__row {
	@include clearfix;
}

.storyintro__lead {
	@include span(12);
	text-align: center;
}

.storyintro__body {
	@include clearfix;
	position: relative;
}

.storyintro__body__content__cell {
	@include span(12);
}

.storyintro__image {
	max-width: 1200px;
	margin: 0 auto;

	img {
		opacity: 0;
		transition: opacity 1s ease;

		&.b-loaded {
			opacity: 1;
		}
	}
}

@include medium-up {
	.storyintro {
		padding: 10vh 0 100px;
	}

	.storyintro__lead {
		@include span(12);
	}

	.storyintro__body__content {
		max-width: $frame-width-small;
		margin: 0 auto;
	}

	.storyintro__body__content__cell {
		@include span(12);
		text-align: center;
	}
}

@include xxlarge-up {
	.storyintro {
		padding-bottom: 40px;
	}

	.storyintro__body__content {
		position: absolute;
		right: 0;
		top: 200px;
		max-width: none;
	}

	.storyintro__body__content__cell {
		@include span(3 last);
		text-align: left;
	}
}

@include smalllaptop() {
	.storyintro {
		padding-top: 8vh;
	}

	.storyintro__image {
		width: 66%;
	}

	.storyintro__lead {
		.e-head-xlarge {
			font-size: 59px;
		}
	}
}

$rulerMaxWidth: 240px;

.chapter__kicker {
	font-size: 24px;
	font-family: $font-secondary;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	text-align: center;
	color: $color-gold;
	padding-bottom: 10px;
	@include medium-up {
		padding-bottom: 20px;
	}
}

.chapter__header {
	max-width: $frame-width-xsmall;
	margin: 0 auto;

	h1 {
		font-family: $font-secondary;
		font-style: italic;
		font-size: 26px;
		line-height: 1.6;
		margin: 0.6em 0;
		text-align: center;
		color: $color-gold;
		font-weight: normal;
		text-transform: none;
		letter-spacing: 0;
	}

	&:before {
		content: ' ';
		display: block;
		height: 10px;
		width: 80%;
		max-width: $rulerMaxWidth;
		margin: 0 auto;
		border-bottom: 2px solid $color-gold;
	}

	&:after {
		content: ' ';
		display: block;
		height: 10px;
		width: 80%;
		max-width: $rulerMaxWidth;
		margin: 0 auto;
		border-bottom: 2px solid $color-gold;
	}
	@include medium-up {
		h1 {
			margin: 1em 0;
			font-size: 30px;
			line-height: 1.4;
		}
	}
	@include large-up {
		h1 {
			font-size: 36px;
		}
	}
	@include xlarge-up {
		h1 {
			font-size: 60px;
			line-height: 1.2;
		}
	}
}

.chapter__decor {
	width: 54px;
	margin: 30px auto 0;

	img {
		width: 100%;
	}
}

.chapter-theme--light {
	.chapter__header {
		h1 {
			color: $color-ivory-light;
		}

		&:before {
			border-color: $color-ivory-light;
		}

		&:after {
			border-color: $color-ivory-light;
		}
	}

	.chapter__kicker {
		color: $color-ivory-light;
	}
}

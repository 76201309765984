$brandImgRatio: 348/250;
$brandWidthMedium: 100px;
$brandWidthLarge: 120px;
$brandWidthXLarge: 160px;
$headerHeight:71px;

.header--nav__left {
	position: absolute;
	top: 28px;
	left: 10px;
	svg {
		width: 30px;
		fill: $primary_gold;
	}
}

.header--nav__right {
	position: absolute;
	top: 28px;
	right: 10px;
	width: 100px;
	text-align: right;
	text-decoration: none;
	font-size: 10px;
}

.global-header {
	position: fixed;
	z-index: 10010;
	top: 0;
	right: 0;
	left: 0;
	height: $headerHeight;
	background: $color-ivory-light;
	user-select: none;
	@at-root {
		.collection-wrap .global-header {
			background: $primary_black;
			background-image: url('../assets/img/bg-tile-dark.jpg');
		}
		.experience-page .global-header {
			background: $primary_black;
		}
	}
	a.global-header__brand {
		width: 80px;
		height: 80px * $brandImgRatio;
		display: block;
		background: url('../assets/img/royal-logo.png') no-repeat;
		background-size: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		@include medium-up {
			width: $brandWidthMedium;
			height: $brandWidthMedium * $brandImgRatio;
			left: 30px;
		}
		@include large-up {
			width: $brandWidthLarge;
			height: $brandWidthLarge * $brandImgRatio;
		}
		@include xlarge-up {
			width: $brandWidthXLarge;
			height: $brandWidthXLarge * $brandImgRatio;
		}
	}
	&::after {
		position: absolute;
		z-index: -1;
		top: 71px;
		right: 0;
		left: 0;
		display: block;
		height: 1px;
		content: '';
		background: $primary_gold;
	}
	.global-header__container {
		width: 100%;
		margin: auto;
		padding: 0 40px;
	}
	&__logo {
		position: absolute;
		top: 15px;
		left: 15px;
		float: left;
		width: 70px;
		height: auto;
		&--dark {
			display: none;
		}
		@at-root {
			.collection-wrap {
				.global-header__logo--dark {
					display: block;
				}
				.global-header__logo--white {
					display: none;
				}
			}
		}
		a {
			display: block;
			overflow: hidden;
			height: 50px;
		}
		img {
			width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}
	&__burger {
		position: absolute;
		top: 18px;
		right: 26px;
		width: 56px;
		height: 50px;
		img {
			display: block;
			width: 30px;
			height: auto;
			margin: 15px auto 0;
		}
	}
}

.global-header__external,
.global-header__menu,
.global-header-dt__menu {
	position: absolute;
	top: 56px;
	right: 0;
	left: -10px;
	float: left;
	background: $color-ivory-light;
	.collection-wrap & {
		background: #2e2e2e;
	}
	@at-root {
		.menu-open .global-header__menu {
			display: block;
		}
	}
	ul {
		margin: 0;
		padding: 20px 0 0;
		list-style: none;
	}
	li {
		width: 100%;
		padding: 30px;
		text-align: center;
	}
	a {
		position: relative;
		padding: 0 0 5px;
		text-decoration: none;
		font-family: Avenir, Arial;
		font-size: 16px;
		&::before {
			position: absolute;
			top: 25px;
			right: 0;
			left: -20px;
			display: block;
			width: 50px;
			height: 2px;
			margin: 0 auto;
			content: '';
			transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
			opacity: 0;
			background: $primary_gold;
			will-change: opacity left;
		}
		&.active,
		&:hover {
			&::before {
				left: 0;
				opacity: 1;
			}
		}
	}
}

.global-header__external {
	display: none;
}

.global-header__menu {
	top: 0;
	padding-top: 56px;
	padding-bottom: 30px;
	box-shadow: 0 3px 10px rgba($color-darkgrey, 0.1);
	transform: translateY(-120%);
	transition: all 0.4s;
	opacity: 0;
	li {
		transform: translateY(-100px);
		opacity: 0;
	}
	@for $i from 0 through 4 {
		$delay: (4 - $i) * 0.1;
		li:nth-child(#{$i}) {
			transition:all 0.4s #{$delay}s;
		}
	}
	.menu-open & {
		transform: translateY(0);
		opacity: 1;
		li {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.menu-open .global-header__menu {
	display: block;
}

.global-header-dt__menu__container {
	display: none;
}

@include medium-up {
	.global-header {
		&::after {
			right: 30px;
			left: 30px;
		}
		@at-root {
			.overflow--hidden .global-header::after {
				right: 0;
				left: 0;
			}
		}
	}
	.global-header__logo {
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}

@include large-up {
	.global-header {
		.global-header__burger,
		.global-header__menu {
			display: none;
		}
		.global-header__external,
		.global-header-dt__menu {
			position: relative;
			top: 0;
			left: 0;
			display: block;
			background: transparent;
			ul {
				padding: 0;
			}
			li {
				display: inline-block;
				width: auto;
				margin-right: 20px;
				padding: 3px 0 0 0;
				line-height: 60px;
				height: $headerHeight;
				position: relative;
			}
			a {
				position: static;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: bold;
				&:before {
					top: auto;
					bottom: -3px;
					left: 0;
				}
				&:hover {
					&:before {}
				}
			}
		}
		.global-header-dt__menu__container {
			display: block;
		}
		.global-header-dt__menu {
			margin-left: $brandWidthLarge + 70px;
			transform: none;
			box-shadow: none;
			padding-top: 0;
			opacity: 1;
			li {
				opacity: 1;
				transform: none;
			}
		}
		.global-header__external {
			top: 0;
			padding-top: 4px;
			right: -10px;
			left: auto;
			float: right;
			a {
				position: relative;
				height: 67px;
				line-height: 60px;
				display: block;
				text-transform: lowercase;
				float: right;
				margin-left: 2em;
				cursor: pointer;
			}
		}
	}
}

@include xlarge-up {
	.global-header {
		.global-header__external,
		.global-header__menu {
			li {
				margin-right: 40px;
			}
			a {
				font-size: 14px;
			}
		}
	}
}
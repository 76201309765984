.story-c {
	@include clearfix;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 60px 0;
	display: none;
	justify-content: center;
    align-content: center;
	flex-direction: column;
}

.story-c__content {
	@include clearfix;
	max-width: 530px;
    width: 100%;
	margin: 0 auto;
	display: block;
}

.story-c__content__block {
	@include span(12);
}

@include medium-up {
	.story-c__content {
		@include clearfix;
	}

	.story-c {
		display: flex;
	}
}

@include large-up {
	.story-c {
		min-height: $partial-height-medium;
	}

	.story-c__content__block {
		max-width: $frame-width-xsmall;
	}

	.story-c__content {
		max-width: $frame-width-xsmall;
	}
}

@include xlarge-up {
	.story-c {
		min-height: $partial-height-large;
	}
}

@include smalllaptop() {
	.story-c {
		min-height: 550px;
	}
}

.timeline-intro {
	padding: 100px 0;
	position: relative;
	color: #fff;

	&:after {
		content: ' ';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $color-darkgrey;
	}

	.e-head-medium {
		color: $color-ivory-light;
	}

	.e-lead-serif-italic-large {
		&,
		p {
			color: $color-ivory-light;
		}
	}
}

.timeline-intro__tringle {
	width: 42px;
	height: 20px;
	position: absolute;
	left: 50%;
	bottom: -20px;
	margin-left: -20px;
	z-index: 2;

	&:after {
		content: ' ';
		display: block;
		@include triangle(42px, 20px, down, $color-darkgrey)
	}
}

.timeline-intro__header {
	margin-bottom: 2px;
}

.timeline-intro__block {
	position: relative;
	@include clearfix;
	z-index: 1;
	max-width: $frame-width-small;
	margin: 0 auto;
}

.timeline-intro__header {
	@include span(12);
	padding-bottom: 30px;
	text-align: center;
}

.timeline-intro__body {
	@include span(12);
	text-align: center;
}

@include medium-up {
	.timeline-intro {
		&:after {
			content: ' ';
			position: absolute;
			top: 0;
			right: -($app-offset-desktop + 1);
			bottom: 0;
			left: -($app-offset-desktop + 1);
			background: $color-darkgrey;
		}
	}
}

@charset "UTF-8";

@font-face {
	font-family: 'Avenir Next LT Pro';
	src: url('../assets/fonts/AvenirNextLTPro-Bold.eot');
	src: url('../assets/fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff'),
		url('../assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype'),
		url('../assets/fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next LT Pro';
	src: url('../assets/fonts/AvenirNextLTPro-Regular.eot');
	src: url('../assets/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
		url('../assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
		url('../assets/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

$font-primary: 'Avenir Next LT Pro' ,sans-serif;
$font-secondary: 'Baskerville' ,  serif;

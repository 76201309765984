* {
	box-sizing: border-box;
}

/* WebKit/Blink Browsers */
::selection {
	background: transparentize($primary_gold,0.2);
	color: #fff;
}

/* Gecko Browsers */
::-moz-selection {
	background: transparentize($primary_gold,0.2);
	color: #fff;
}

html {
	padding: 0;
	margin: 0;
	height: 100%;
	-webkit-tap-highlight-color: transparentize($primary_gold,0.2);
}

body {
	margin: 0;
	padding: 0;
	background-color: $color-ivory-light;
	overflow-x: hidden;

	&.overflow--hidden {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
}

.anchor-point {
	position: relative;
	top: -300px;
}

.outerbody {
	overflow: hidden;
	background: $color-ivory-light;
}

img {
	pointer-events: none;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 70px 0 0;
	display: block;
	min-height: calc(100% - 80px);
}

.vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.rc__logo {
	position: relative;
	display: block;
	bottom: 40px;
	width: 140px;
	margin: 0 auto;
}
@media #{$medium-up} {
	#app {
		border: 1px solid $primary_gold;
		margin: 70px $app-offset-desktop 30px;
		min-height: calc(100vh - 100px);
	}
}

.story-page {
	.hide-for-story {
		display: none;
	}
	background: $color-ivory;
}

.craft-page {
	.hide-for-craft {
		display: none;
	}
}

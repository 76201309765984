$zIndexContentBgLayer: 10;
$zIndexGallery: 10;
$zIndexPaging: 30;
$zIndexNavButtons: 40;
.modal__behindscenes {
  position: fixed;
  padding: 0 0 0;
  background: $color-ivory-light;
  @include large-up {
    width: 100vw;
  }
  .swiper__navbuttons {
    display: none;
  }
}

.behindscenesoverlay {
  .swiper__paging__title {
    display: none;
  }
}

.behindscenesoverlay__paging {
  position: absolute;
  right: 0;
  top: 120px;
  left: 0;
  z-index: 20;
}

.behindscenesoverlay__gallery {
  margin-top: 180px;
  position: relative;
}

.behindscenesoverlay__slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  opacity: 0;
  &.behindscenesoverlay__slide--active {
    opacity: 1;
    display: flex;
  }
}

.behindscenesoverlay__content__cell {
  @include span(12);
  padding-bottom: 100px;
}

.behindscenesoverlay__content {
  padding-bottom: 40px;
}

.behindscenesoverlay__header {
  padding-top: 40px;
}

.behindscenesoverlay__image {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 100;
  padding-top: 200px
}

.behindscenesoverlay__fullscreen {
  z-index: 6;
}

.behindscenesoverlay__fullscreen__inner {
  @include span(12);
  padding-top: 120px;
}

.behindscenesoverlay__fullscreen__media {
  img {
    width: 100%;
  }
}

.behindscenesoverlay__fullscreen__description {
  text-align: center;
}

.behindscenesoverlay__fullscreen__note {
  display: none;
}

#modal__behind__video {
  margin: 0 auto;
}

@include medium-up {
  .behindscenesoverlay__fullscreen__inner {
    padding-left: calc(5% + 60px);
    padding-right: calc(5% + 60px);
  }
  .behindscenesoverlay__body__lead {
    font-size: 36px;
  }
}

@include md-lg-up {
  $imageSpan: 5;
  $contentSpan: 7;
  .modal__behindscenes {
    overflow: hidden;
  }
  .behindscenesoverlay {
    .swiper__navbuttons {
      display: block;
      z-index: 100;
    }
  }
  .behindscenesoverlay__paging {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    width: span($contentSpan);
    padding: 100px gutter() 0;
    z-index: $zIndexPaging;
    background-image: linear-gradient($color-ivory-light 80%, rgba($color-ivory-light, 0));
    .behindscenesoverlay--fullscreen & {
      margin: 0 auto;
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      padding: 6vh 0 0;
      background: transparent;
      .swiper__paging {
        width: 50%;
        margin: 0 auto;
      }
    }
    .swiper__paging-wrapper {
      padding: 0 5%;
    }
  }
  .behindscenesoverlay__contentbglayer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: $color-ivory-light;
    z-index: 2;
    transform: translateX(span($imageSpan));
    transition: transform 0.3s 0.6s;
  }
  .behindscenesoverlay__gallery {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin-top: 0;
  }
  .behindscenesoverlay__slide {
    display: block;
  }
  .behindscenesoverlay__content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: span($contentSpan);
    z-index: 3;
  }
  .behindscenesoverlay__content__cell {
    width: span(12);
    padding-left: gutter();
    padding-right: gutter() * 2;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  }
  .behindscenesoverlay__header {
    padding-top: 220px;
  }
  .behindscenesoverlay__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: span($imageSpan);
    height: auto;
    z-index: 1;
  }
  .behindscenesoverlay__fullscreen__video {
    max-width: 960px;
    margin: 0 auto;
  }
  .behindscenesoverlay__fullscreen__inner {
    // padding-top: 28vh;
    padding-top: 60px;
  }
  .behindscenesoverlay__fullscreen__media {
    max-width: 1200px;
    margin: 0 auto;
  }
  .behindscenesoverlay__fullscreen__description {
    padding-top: 10px;
    max-width: 800px;
    margin: 0 auto;
    p {
      font-size: 16px;
    }
  }
  .behindscenesoverlay__fullscreen__note {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 gutter() 20px;
    margin-top: 100px;
    p {
      font-size: 12px;
      text-align: center;
      line-height: 1.2;
    }
  }
  .behindscenesoverlay__slide--fullscreen {
    display: flex;
    justify-content: center; //padding-bottom: 5%;
  }
}

@include large-up {
  $imageSpan: 6;
  $contentSpan: 6;
  .behindscenesoverlay__slide {
    display: block;
  }
  .behindscenesoverlay__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: span(6);
    height: auto;
  }
  .behindscenesoverlay__paging {
    width: span($imageSpan);
  }
  .behindscenesoverlay__content {
    width: span($contentSpan);
  }
  .behindscenesoverlay__contentbglayer {
    transform: translateX(span($imageSpan))
  }
}

@include xlarge-up {
  $imageSpan: 7;
  $contentSpan: 5;
  .behindscenesoverlay__image {
    width: span($imageSpan);
  }
  .behindscenesoverlay__paging {
    width: span($contentSpan);
  }
  .behindscenesoverlay__content {
    width: span($contentSpan);
  }
  .behindscenesoverlay__contentbglayer {
    transform: translateX(span($imageSpan))
  }
}

.behindscenesoverlay--contentbglayer-fullscreen {
  .behindscenesoverlay__contentbglayer {
    transform: translateX(0);
    transition: transform 0.3s 0.2s;
  }
}

//$heightWidth = "#{$screen} and (max-height: 900px) and (min-width:#{lower-bound($medium-range)})"
@media (max-height: 840px) and (min-width:#{lower-bound($large-range)}) {
  .behindscenesoverlay--fullscreen {
    .behindscenesoverlay__paging {
      top: 2vh;
    }
  }
}

@media (max-height: 900px) {
  .behindscenesoverlay__fullscreen__media {
    max-width: 760px;
  }
}

@media (max-height: 700px) {
  .behindscenesoverlay__fullscreen__description {
    padding-top: 10px;
    p {
      line-height: 18px;
    }
  }
  .behindscenesoverlay__fullscreen__inner {
    padding-top: 0;
  }
}

@media (max-height: 660px) and (min-width:#{lower-bound($large-range)}) {
  .behindscenesoverlay__fullscreen__description {
    padding-top: 10px;
    p {
      line-height: 18px;
    }
  }
  .behindscenesoverlay__fullscreen__inner {
    padding-top: 0;
  }
  .behindscenesoverlay--fullscreen {
    .behindscenesoverlay__paging {
      top: 0;
      padding-top: 4vh;
    }
  }
}
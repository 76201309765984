.specialorders {
	position: relative;
	padding-bottom: 60px;

	.plainswiper {
		max-width: 100%;
	}

	.plainswiper__slide {
		min-height: 540px;
	}

	.swiper__navbuttons {
		display: none;
	}

	.e-head-serif-large {
		max-width: 500px;
	}
}

.specialorders__decor {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 20%;
	z-index: 200;
	background-image: url("../assets/img/flower-decor-01.png");
	background-repeat: no-repeat;
	background-size: contain;
}

@include md-lg-up {
	.specialorders {
		.swiper__navbuttons {
			display: block;
		}
	}
}

@include medium-up {
	.specialorders {
		.plainswiper__slide {
			min-height: 480px;
		}
	}
}

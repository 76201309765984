$pageRowHeight: 40px;
$stepContainerSize: 40px;
$bulletInactiveSize: 10px;
$bulletActiveSize: 34px;
$bulletActiveInsideSize: 16px;
$stepTextContainerSize: 220px;

.swiper__paging {
	user-select: none;

	&.swiper__paging--with-label {
		padding-bottom: 50px;
	}

	a:active {
		background-color: transparent;
		-webkit-tap-highlight-color: rgba(0,0,0, 0.1);
		border-radius: 50%;
	}
}

.swiper__paging-wrapper {
	position: relative;
	text-align: justify;
	vertical-align: baseline;
	font-size: 0.1px;
	border-width: 1px 0 0;
	margin: 0;
	padding: 0 5%;
	height: $pageRowHeight;

	&:after {
		content: '';
		width: 100%;
		display: inline-block;
	}

	&:before {
		position: absolute;
		content: ' ';
		display: block;
		background: $color-gold;
		height: 1px;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: $pageRowHeight/2;
		opacity: 0.5;
	}
}

.swiper__paging__step {
	display: inline-block;
	vertical-align: top;
	width: $stepContainerSize;
	height: $pageRowHeight;
	float: none;
}

.swiper__paging__link {
	position: relative;
	margin: 0;
	width: $stepContainerSize;
	height: $pageRowHeight;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 13px;
	display: inline-block;
}

.swiper__paging__title {
	position: absolute;
	text-align: center;
	left: -$stepTextContainerSize/2+ $stepContainerSize/2;
	bottom: -36px;
	display: block;
	width: $stepTextContainerSize;
	pointer-events: none;
	opacity: 0;
	font-weight: normal;
	font-size: 16px;
	transform: translateY(-30px);
	transition: transform 0.2s, opacity 0.2s;
}

.swiper__paging__dot {
	position: relative;
	display: block;
	background: $color-gold;
	transform: scale(0.3);
	width: $bulletActiveSize;
	height: $bulletActiveSize;
	margin: $pageRowHeight/2 - $bulletActiveSize/2 auto;
	border-radius: 50%;
	border: 1px solid $color-gold;
	transition: transform 0.2s, background-color 0.2s;

	&:after {
		content: ' ';
		display: block;
		width: $bulletActiveInsideSize;
		height: $bulletActiveInsideSize;
		background-color: $color-gold;
		border-radius: $bulletActiveInsideSize/2;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -$bulletActiveInsideSize/2;
		margin-left: -$bulletActiveInsideSize/2;
		opacity: 0;
	}
}

.swiper__paging__step--selected {
	.swiper__paging__dot {
		position: relative;
		display: block;
		width: $bulletActiveSize;
		height: $bulletActiveSize;
		margin: $pageRowHeight/2 - $bulletActiveSize/2 auto;
		background: $color-ivory;
		border-radius: 50%;
		cursor: default;
		transform: scale(1);

		&:after {
			opacity: 1;
		}
	}

	.swiper__paging__title {
		opacity: 1;
		transform: translateY(00px);
	}
}

.swiper__paging--theme-darkgrey {
	.swiper__paging__dot {
		background: $color-ivory-light;
		border-color: $color-ivory-light;

		&:after {
			background-color: $color-ivory-light;
		}
	}

	.swiper__paging-wrapper {
		&:before {
			background: $color-ivory-light;
		}
	}

	.swiper__paging__step--selected {
		.swiper__paging__dot {
			background: $color-darkgrey;
		}
	}
}

.swiper__navbtn-container {
	@include clearfix;
}

.swiper__navbuttons__cell {
	width: 50%;
	float: left;
	margin-top: -80px;
}

.swiper__navbuttons__cell--prev {
	.swiper__navbtn {
		transform: translateX(-80px);
	}
}

.swiper__navbuttons__cell--next {
	.swiper__navbtn {
		transform: translateX(80px);
	}
}

.swiper__navbtn {
	display: block;
	height: 70px;
	background: $color-gold;
	fill: $color-ivory-light;
	padding-top: 6px;
	opacity: 0;
	cursor: pointer;
	transition: opacity 0.2s, transform 0.2s;

	svg {
		width: 56px;
		height: 56px;
		display: block;
		margin: 0 auto;
	}

	&.swiper__navbtn--show {
		opacity: 1;
		transform: translateX(0);
	}
}

.swiper__navbuttons__cell--prev {
	.swiper__navbtn {
		margin-right: 5px;
	}
}

.swiper__navbuttons__cell--next {
	.swiper__navbtn {
		margin-left: 5px;
	}
}

@include medium-up {
	.swiper__paging-wrapper {
		padding-left: 10%;
		padding-right: 10%;
	}

	.swiper__paging__title {
		font-size: 25px;
	}

	.swiper__navbuttons__cell {
		float: none;
		position: absolute;
		top: 50%;
		width: auto;
		z-index: 10;
	}

	.swiper__navbtn {
		display: block;
		width: 60px;
		height: 160px;
		background: $color-gold;
		padding-top: 50px;
	}

	.swiper__navbuttons__cell--prev {
		left: 0;
	}

	.swiper__navbuttons__cell--next {
		right: 0;
	}
}

.newtimelineslider {
	@include clearfix;
	padding: 60px 0;
	position: relative;

	.swiper__paging {
		margin-bottom: 30px;
	}

	.swiper__paging-wrapper {
		margin: 0 10%;
	}

	p {
		margin: 0;
	}

	.swiper__navbuttons {
		display: none;
	}
}

.nts__gallery {
	position: relative;
	overflow: hidden;
}

.nts__gallery__slide {
	position: absolute;
	width: 100%;
	opacity: 0;

	.shortdescription {
		margin: 60px 0 10px 20px;
		padding-bottom: 10px;
		max-width: 640px;
	}

	&.nts__gallery__slide--active {
		opacity: 1;
		display: block;
	}
}

.nts__event {
	@include clearfix;
}

.nts__event__header {
	@include span(12);
	font-size: 30px;
	margin-bottom: 30px;
}

.nts__event__body {
	@include span(12);
}

.nts__event__content {
	padding-bottom: 40px;
}

.nts__event__lead {
	font-size: 30px;
	color: $color-gold;
	font-style: italic;
	font-family: $font-secondary;
	margin-bottom: 30px;
}

.nts__event__image {
	z-index: 10;
	position: relative;
	margin-bottom: 60px;

	img {
		width: 100%;
	}
}

@include md-lg-up {
	.newtimelineslider {
		min-height: 900px;

		.swiper__navbuttons {
			display: block;
		}

		.swiper__navbuttons__cell--prev {
			left: -($app-offset-desktop + 1px);
		}

		.swiper__navbuttons__cell--next {
			right: -($app-offset-desktop + 1px);
		}
	}

	.nts__gallery {
		margin-top: 6%;
	}

	.nts__event__image {
		margin-left: gutter(1);
		@include span(5 last no-gutter);
	}

	.nts__event__header {
		@include span(12);
		float: none;
	}

	.nts__event__body {
		@include span(12);
		float: none;
	}
}

@include large-up {
	.newtimelineslider {
		min-height: 900px;
		padding-top: 6%;
		padding-bottom: 6%;

		.swiper__paging-wrapper {
			margin: 0 20%;
		}
	}

	.nts__event__image {
		margin-left: 0;
		@include span(4 last no-gutter)
	}

	.nts__event__content {
		@include span(8 no-gutter)
	}

	.nts__event__header {
		@include span(12);
	}

	@include with-layout($susyLayoutInner) {
		.nts__event__header__cell {
			@include span(12);
		}

		.nts__event__cell-1 {
			@include span(6);
		}

		.nts__event__cell-2 {
			@include span(6 last)
		}
	}

	.nts__event__container {
		max-width: calc(100vw - (100vw - 1440px)/2);
		float: right;
		width: 100%;
	}
}

@include xlarge-up {
	.nts__event__image {
		margin-left: 0;
		@include span(5 last no-gutter)
	}

	.nts__event__content {
		@include span(7 no-gutter)
	}
}

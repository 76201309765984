.story-b {
	@include clearfix;
	background-size: cover;
	background-position: 60% 50%;
	background-repeat: no-repeat;
	padding: 60px 0;
	justify-content: center;
	flex-direction: column;
	display: none;
}

.story-b__content {
	@include clearfix;
}

.story-b__content__block {
	@include span(12);
}

@include medium-up {
	.story-b {
		display: flex;
	}
}

@include large-up {
	.story-b {
		min-height: $partial-height-medium;
	}

	.story-b__content {
		@include span(8);
	}

	.story-b__content__block {
		max-width: $frame-width-xsmall;
	}
}

@include xlarge-up {
	.story-b {
		min-height: $partial-height-large;
	}

	.story-b__content {
		margin-left: 4%;
	}

	.story-b__content__block {
		max-width: 680px;
	}
}
